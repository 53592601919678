import React from "react"
import { Link } from "react-router-dom"
import {
  Phone,
  Mail,
  Facebook,
  Instagram,

  Linkedin,
  ChevronRight,

} from "lucide-react"
import { motion } from "framer-motion"
import ContactFloatingButton from "../../utils/ContactFlipCard/ContactFlipCard"

import twiiterx from "../../Assets/icons/x.svg"

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-br from-blue-900 to-blue-950 text-white py-16 overflow-hidden">
      {/* Professional accent bar */}
      <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-400 to-blue-600"></div>
      {/* Subtle animated background */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -left-1/4 -top-1/4 w-1/2 h-1/2 bg-blue-400 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob"></div>
        <div className="absolute -right-1/4 -bottom-1/4 w-1/2 h-1/2 bg-blue-600 rounded-full mix-blend-multiply filter blur-3xl opacity-10 animate-blob animation-delay-2000"></div>
      </div>

      <div className="container mx-auto px-4 relative z-10">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4 mb-12 lg:mb-0">
            <motion.h3
              className="text-3xl font-bold mb-8 text-blue-300"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Connect with YassinGlass
            </motion.h3>
            <motion.div
              className="space-y-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <div className="flex items-center group">
                <div className="w-12 h-12 rounded-lg bg-blue-800 flex items-center justify-center mr-4 group-hover:bg-blue-700 transition-all duration-300">
                  <Phone className="w-6 h-6 text-blue-300" />
                </div>
                <div>
                  <p className="font-semibold text-lg text-blue-200">Call us</p>
                  <p className="text-blue-100">+201098994460</p>
                </div>
              </div>
              <div className="flex items-center group">
                <div className="w-12 h-12 rounded-lg bg-blue-800 flex items-center justify-center mr-4 group-hover:bg-blue-700 transition-all duration-300">
                  <Mail className="w-6 h-6 text-blue-300" />
                </div>
                <div>
                  <p className="font-semibold text-lg text-blue-200">
                    Our Email
                  </p>
                  <p className="text-blue-100">ysglassco@gmail.com</p>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="w-full lg:w-1/2 px-4 flex flex-col justify-center items-center ">
            <motion.div
              className="flex space-x-4 justify-center  lg:justify-end mb-8"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              {[
                {
                  icon: Facebook,
                  href: "https://www.facebook.com/YassinGlass1",
                },
                {
                  icon: Instagram,
                  href: "https://www.instagram.com/yassinglass101/",
                },
                { iconx: twiiterx, href: "https://twitter.com/YassinGlass" },
                {
                  icon: Linkedin,
                  href: "https://www.linkedin.com/in/yassin-glass-311b31234/",
                },
              ].map(({ icon: Icon, href, iconx }, index) => (
                <a
                  key={index}
                  href={href}
                  target="_blank"
                  className="w-10 h-10 rounded-lg bg-blue-800 flex items-center justify-center hover:bg-blue-700 transition-all duration-300 transform hover:scale-110"
                >
                  {iconx ? (
                    <img
                      src={iconx}
                      alt="twitter"
                      className="w-5 h-5 text-blue-300"
                    />
                  ) : (
                    <Icon className="w-5 h-5 text-blue-300" />
                  )}
                </a>
              ))}
            </motion.div>
            <motion.div
              className="text-center lg:text-right"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <Link
                to="/contact-us"
                className="inline-flex items-center px-8 py-3 rounded-lg bg-blue-600 text-white font-bold text-lg transition-all duration-300 transform hover:bg-blue-500 hover:shadow-lg no-underline"
              >
                Contact Us
                <ChevronRight className="ml-2 w-5 h-5" />
              </Link>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Professional accent bar */}
      <div className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-blue-400 to-blue-600"></div>

      {/* Whatsapp and phone number flip button */}
      <ContactFloatingButton />
    </footer>
  )
}

export default Footer
