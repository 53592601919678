import React, { useState, useRef, useEffect } from "react"
import { motion } from "framer-motion"
import {
  Play,
  Pause,
  SkipBack,
  SkipForward,
  Volume2,
  VolumeX,
  Maximize2,
  Minimize2,
} from "lucide-react"
import mainVideo from "../../Assets/videos/mainwebvideo.mp4"

export default function VideoControl() {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMuted, setIsMuted] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false) 
  const videoRef = useRef(null)
  const videoWrapperRef = useRef(null)

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    }

    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          playVideo()
        } else {
          pauseVideo()
        }
      })
    }

    const observer = new IntersectionObserver(handleIntersect, options)

    if (videoWrapperRef.current) {
      observer.observe(videoWrapperRef.current)
    }

    return () => {
      if (videoWrapperRef.current) {
        observer.unobserve(videoWrapperRef.current)
      }
    }
  }, [])

  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play()
      setIsPlaying(true)
    }
  }

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause()
      setIsPlaying(false)
    }
  }

  const togglePlay = () => {
    if (isPlaying) {
      pauseVideo()
    } else {
      playVideo()
    }
  }

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted
      setIsMuted(!isMuted)
    }
  }

  const skipBackward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime -= 10
    }
  }

  const skipForward = () => {
    if (videoRef.current) {
      videoRef.current.currentTime += 10
    }
  }

  const toggleFullScreen = () => {
    if (!isFullScreen) {
      if (videoWrapperRef.current.requestFullscreen) {
        videoWrapperRef.current.requestFullscreen()
      } else if (videoWrapperRef.current.webkitRequestFullscreen) {
        // Safari support
        videoWrapperRef.current.webkitRequestFullscreen()
      } else if (videoWrapperRef.current.msRequestFullscreen) {
        // IE/Edge support
        videoWrapperRef.current.msRequestFullscreen()
      }
      setIsFullScreen(true)
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        // Safari support
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        // IE/Edge support
        document.msExitFullscreen()
      }
      setIsFullScreen(false)
    }
  }

  return (
    <motion.div
      className="content-video flex flex-col md:flex-row items-center justify-between gap-8"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8 }}
    >
      <div
        ref={videoWrapperRef}
        className="video-wrapper relative w-full md:w-1/2 aspect-video"
      >
        <video
          ref={videoRef}
          id="yassinGlassVideo"
          src={mainVideo}
          className="w-full h-full object-cover rounded-lg"
          loop
          playsInline
          muted={isMuted} 
        />
        <div className="absolute bottom-0 left-0 right-0 flex justify-center items-center p-4 bg-black bg-opacity-10 md:bg-opacity-50">
          <motion.button
            className="text-white mx-2 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={skipBackward}
          >
            <SkipBack size={24} />
          </motion.button>
          <motion.button
            className="text-white mx-2 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={togglePlay}
          >
            {isPlaying ? <Pause size={24} /> : <Play size={24} />}
          </motion.button>
          <motion.button
            className="text-white mx-2 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={skipForward}
          >
            <SkipForward size={24} />
          </motion.button>
          <motion.button
            className="text-white mx-2 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleMute}
          >
            {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
          </motion.button>
          <motion.button
            className="text-white mx-2 focus:outline-none"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleFullScreen}
          >
            {isFullScreen ? <Minimize2 size={24} /> : <Maximize2 size={24} />}
          </motion.button>
        </div>
      </div>

      <div className="text-content w-full md:w-1/2">
        <motion.h2
          className="text-3xl font-bold mb-4"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          About Yassin GLASS
        </motion.h2>
        <motion.p
          className="text-lg"
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          Yassin glass is a tempered glass installation company established by
          Engineer Mahmoud Yassin in 2005. We specialize in 10mm and 12mm
          tempered glass, including glass facades, glass stairs, shower cabins,
          and more.
        </motion.p>
      </div>
    </motion.div>
  )
}
