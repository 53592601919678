import React, { useEffect, useState } from "react"
import { ChevronLeft, ChevronRight, Pause, Play, X } from "lucide-react"

export default function Pslider({ title, heading, images, container }) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isAutoPlaying, setIsAutoPlaying] = useState(false)
  const [showGallery, setShowGallery] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null)

  // const visibleImages = images.slice(0, 5)

  useEffect(() => {
    let interval
    if (isAutoPlaying && !showGallery && selectedImageIndex === null) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
      }, 5000)
    }
    return () => clearInterval(interval)
  }, [isAutoPlaying, showGallery, selectedImageIndex, images.length])

  useEffect(() => {
    if (showGallery || selectedImageIndex !== null) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
    return () => {
      document.body.style.overflow = "visible"
    }
  }, [showGallery, selectedImageIndex])

  const goToSlide = (index) => {
    setCurrentIndex(index)
  }

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    )
  }

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
  }

  const toggleAutoPlay = () => {
    setIsAutoPlaying(!isAutoPlaying)
  }

  const openGallery = () => {
    setShowGallery(true)
  }

  const closeGallery = () => {
    if (selectedImageIndex !== null) {
      setSelectedImageIndex(null) // Go back to gallery
    } else {
      setShowGallery(false) // Close gallery
    }
  }

  const viewFullSizeImage = (index) => {
    setSelectedImageIndex(index)
  }

  return (
    <>
      <section
        className={`relative py-5 overflow-hidden rounded-xl ${container}`}
      >
        <div className="container mx-auto px-4">
          <div className="text-center mb-12">
            <span className="text-blue-500 font-semibold text-sm uppercase tracking-wider">
              {heading}
            </span>
            <h2 className="text-4xl font-bold mt-2 mb-4">{title}</h2>
          </div>

          <div className="relative min-h-[400px] md:min-h-[400px] lg:min-h-[600px] w-full max-w-5xl mx-auto">
            {images.map((img, index) => (
              <div
                key={index}
                className={`absolute top-0 left-0 w-full h-full transition-opacity duration-1000 ${
                  index === currentIndex ? "opacity-100" : "opacity-0"
                }`}
              >
                <img
                  src={img}
                  alt={`Glass Facade ${index + 1}`}
                  className="w-full h-full object-cover rounded-lg shadow-2xl"
                />
              </div>
            ))}

            <button
              onClick={toggleAutoPlay}
              className="absolute top-4 right-4 z-10 bg-black bg-opacity-50 hover:bg-opacity-70 text-white p-2 rounded-full transition-all duration-300"
            >
              {isAutoPlaying ? <Pause size={24} /> : <Play size={24} />}
            </button>

            <button
              onClick={goToPrevious}
              className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-white bg-opacity-30 hover:bg-opacity-50 text-blue-500 p-2 rounded-full transition-all duration-300"
            >
              <ChevronLeft size={24} />
            </button>
            <button
              onClick={goToNext}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-white bg-opacity-30 hover:bg-opacity-50 text-blue-500 p-2 rounded-full transition-all duration-300"
            >
              <ChevronRight size={24} />
            </button>

            {/* <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => goToSlide(index)}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === currentIndex
                      ? "bg-blue-500 scale-125"
                      : "bg-white"
                  }`}
                />
              ))}
            </div> */}
          </div>

          {images.length > 5 && (
            <div className="text-center mt-8">
              <button
                onClick={openGallery}
                className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg transition-colors duration-300"
              >
                View More
              </button>
            </div>
          )}
        </div>
      </section>

      {showGallery && selectedImageIndex === null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm"
            onClick={closeGallery}
          ></div>
          <div className="relative w-full max-w-4xl mx-auto z-10 bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold">Gallery</h3>
              <button
                onClick={closeGallery}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-4 max-h-[80vh] overflow-y-auto">
              <div className="grid grid-cols-2 gap-4">
                {images.map((img, index) => (
                  <div
                    key={index}
                    className="aspect-w-16 aspect-h-9 cursor-pointer"
                    onClick={() => viewFullSizeImage(index)}
                  >
                    <img
                      src={img}
                      alt={`Gallery image ${index + 1}`}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedImageIndex !== null && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div
            className="absolute inset-0 bg-black bg-opacity-75 backdrop-filter backdrop-blur-sm"
            onClick={closeGallery}
          ></div>
          <div className="relative w-full max-w-4xl mx-auto z-10 bg-white rounded-lg shadow-xl overflow-hidden">
            <div className="flex justify-between items-center p-4 border-b">
              <h3 className="text-xl font-semibold">Full Image View</h3>
              <button
                onClick={closeGallery}
                className="text-gray-500 hover:text-gray-700 transition-colors duration-300"
              >
                <X size={24} />
              </button>
            </div>
            <div className="p-4">
              <img
                src={images[selectedImageIndex]}
                alt={`Full view image ${selectedImageIndex + 1}`}
                className="w-full h-full object-cover rounded-lg"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
